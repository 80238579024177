<!--
 * @Author: Akko
 * @Description: 服务端跳转接收页
-->
<template>
    <div />
</template>

<script lang="javascript">
import { serLogin, getProduct } from "../api/index";
import { mapMutations } from 'vuex'
export default {
    name: "Auth",
    created() {
        this.getToken()
    },
    methods: {
        ...mapMutations('gifted', ['updateUser']),
        async getToken() {
            // localStorage.clear()
            // localStorage.removeItem("token")
            // localStorage.removeItem("user")
            try {
                const res = await serLogin({ token: this.$route.params.key })
                if (res.data.token == null) {
                    this.$message.error("获取登录认证失败！")
                    return
                }
                if (res.code === 200) {
                    localStorage.setItem("token", res.data.token);
                    this.$store.commit("setToken")
                    // localStorage.setItem("user", JSON.stringify(d));
                    // this.$store.commit("user", res.data);
                    this.updateUser(res.data) // 存到vuex
                    localStorage.setItem("user", JSON.stringify(res.data))

                    // 神策埋点
                    this.$sensors.bind("identity_client_user_id", res.data.userId + "")
                    this.$sensors.bind("$identity_mobile", res.data.phone + "")


                    if(res.data.orderId) {
                        this.getData(res.data.orderId)
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async getData(order_id) {
            try {
                const res = await getProduct({ order_id })
                if(res.code === 200) {
                    switch (res.data.product_id.type) {
                        case "优才":
                            if (this.$route.query.pdf) {
                                this.$router.push({
                                    name: "Gifted",
                                    params: {
                                        id: order_id,
                                    },
                                    query: {
                                        preview: this.$route.query.pdf
                                    }
                                })
                            } else {
                                this.$router.push({
                                    name: "Gifted",
                                    params: {
                                        id: order_id,
                                    }
                                })
                            }
                            break;
                            case "澳门优才":
                            if (this.$route.query.pdf) {
                                this.$router.push({
                                    name: "MacaoGifted",
                                    params: {
                                        id: order_id,
                                    },
                                    query: {
                                        preview: this.$route.query.pdf
                                    }
                                })
                            } else {
                                this.$router.push({
                                    name: "MacaoGifted",
                                    params: {
                                        id: order_id,
                                    }
                                })
                            }
                            break;
                        case "随迁":
                            // if (res.data.product_id.name === "新增随迁人员") {
                                this.$router.push({
                                    name: "Transfer",
                                    params: {
                                        id: order_id,
                                    },
                                })
                            // }
                            break;
                        default:
                            break;
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
};
</script>

<style lang="less" scoped>
</style>
